import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero2 from '../components/Hero2';

export default function notFound() {
  return (
    <Layout>
      <SEO title="Page not found!" />
      <Hero2
        title1="404"
        title2="Oh no!"
        para="Sorry, the page you're looking for doesn't exist."
      />
    </Layout>
  );
}
